<template>
    <div>
        <v-icon small class="mr-2" @click="updateApp"
                v-if="checkUserPermission('EDIT')">
            mdi-pencil
        </v-icon>
        <v-icon color="red" small
                @click="dialogDelete = true"
                :disabled="dataDelete.length > 0"
                v-if="checkUserPermission('DELETE')">
            mdi-delete
        </v-icon>
        <dialog-delete :darkmode="darkmode"
                       :dialog-delete="dialogDelete"
                       v-on:closeDelete="cancelDelete"
                       v-on:deleteItemConfirm="deleteItem" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import DialogDelete from "../../../../components/shared/notify/DialogDelete";

    export default {
        components: { DialogDelete },
        props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
        data() {
            return {
                dialogDelete: false,
            };
        },
        methods: {
            async updateApp() {
                await this.fetchCurrentApp(this.item);
                await this.fetchPopUp(true);
            },
            cancelDelete() {
                this.dialogDelete = false
            },
            async deleteItem() {
                await this.deleteApp({
                    vm: this,
                    data: { deleteItems: [{ _id: this.item._id }] }
                });
                await this.fetchListApp(this.params)
                this.dialogDelete = false;
            },
            ...mapActions("$_configApp", ["fetchPopUp", "fetchListApp", "deleteApp", "fetchCurrentApp"]),
        },

    };
</script>

<style lang="scss" scoped>
</style>
